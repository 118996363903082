<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-1"
          elevation="0"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
      />

      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("users.user")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      persistent-hint
                      v-model="editedItem.userName"
                      :rules="[rules.required]"
                      :label="$t('users.userName')"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="editedIndex == -1" cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.password"
                      :label="$t('users.password')"
                      :rules="[rules.required]"
                      type="password"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="editedIndex == -1" cols="12" md="6">
                    <v-text-field
                      type="password"
                      v-model="editedItem.confirmPassword"
                      :rules="[rules.required, rules.confirmPassword]"
                      :label="$t('users.confirmPassword')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.email"
                      :rules="[rules.required, rules.email]"
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.phoneNumber"
                      :label="$t('phone')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" v-if="isSuperAdmin">
                    <v-select
                      :items="branches"
                      v-model="editedItem.branchGuid"
                      :label="$t('branches.branchName')"
                      item-text="branchName"
                      item-value="guid"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="groups"
                      v-model="editedItem.groupGuid"
                      :label="$t('groups.groupName')"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="doctors"
                      v-model="editedItem.doctorGuid"
                      :label="$t('doctors.doctorName')"
                      item-text="doctorName"
                      item-value="guid"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>

      <v-dialog v-model="dialogChangePassword" persistent max-width="400px">
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class=""> {{ $t("users.changePassword") }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.password"
                      :label="$t('users.password')"
                      :rules="[rules.required]"
                      type="password"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      type="password"
                      v-model="editedItem.confirmPassword"
                      :rules="[rules.required, rules.confirmPassword]"
                      :label="$t('users.confirmPassword')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="saveChangePassword"
                :disabled="!valid1"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="closeChangePassword">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { ConfirmDialog, DataTableComponent },
  data() {
    return {
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",

      doctors: [],
      users: [],
      groups: [],
      branches: [],
      editedItem: {},
      defaultItem: {
        userName: "",
        email: null,
        password: "",
        confirmPassword: "",
        phoneNumber: null,
        userGroup: "",
        branchGuid: "",
        doctorGuid: "",
        groupGuid: "",
      },
      isSuperAdmin: localStorage.getItem("isSuperAdmin") == "true",
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
          this.editedItem.confirmPassword === this.editedItem.password ||
          this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
      },
    };
  },

  computed: {
    headers() {
      var list = [
        { text: this.$t("users.userName"), value: "userName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("phone"), value: "phoneNumber" },
        { text: this.$t("branches.branchName"), value: "branchName" },
        { text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("groups.groupName"), value: "groupName" },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      actions.templates.push({
        type: "btn",
        icon: "mdi-key",
        text: this.$t("users.changePassword"),
        click: this.changePassword,
      });
      actions.templates.push({
        type: "btn",
        icon: "mdi-pencil",
        text: this.$t("edit"),
        click: this.editItem,
      });
      actions.templates.push({
        type: "btn",
        icon: "mdi-delete",
        text: this.$t("delete"),
        click: this.deleteItem,
        color: "red darken-2",
      });
      list.push(actions);
      return list;
    },
  },

  created() {
    this.loading = true;
    this.refreshTable();

    this.$axios.get("Account/GetGroups").then((response) => {
      this.groups = response.data.data;
    });

    if (this.isInRole("6"))
      this.$axios.get("Doctor").then((response) => {
        this.doctors = response.data.data;
        this.doctors.push({
          doctorName: "none",
          guid: "",
        });
      });

    if (this.isSuperAdmin) {
      this.$axios.get("Branch").then((response) => {
        this.branches = response.data.data;
      });
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    changePassword(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogChangePassword = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.users[this.editedIndex];
      this.$axios
        .delete("Account/DeleteUser?id=" + deleteItem.id)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeChangePassword() {
      this.dialogChangePassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("Account/GetUsers")
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.users[this.editedIndex];
          updatedItem.userName = this.editedItem.userName;
          updatedItem.email = this.editedItem.email;
          updatedItem.phoneNumber = this.editedItem.phoneNumber;
          updatedItem.branchGuid = this.editedItem.branchGuid;
          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.groupGuid = this.editedItem.groupGuid;

          this.$axios
            .post("Account/UpdateUser", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.$axios.get("Account/GetGroups").then((response) => {
                this.groups = response.data.data;

                this.refreshTable();
              });
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("Account/AddUser", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.$axios.get("Account/GetGroups").then((response) => {
                this.groups = response.data.data;

                this.refreshTable();
              });
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
    saveChangePassword() {
      var val = this.$refs.form1.validate();
      if (val) {
        var updatedItem = this.users[this.editedIndex];
        var changePasswordModel = {
          id: updatedItem.id,
          password: this.editedItem.password,
          confirmPassword: this.editedItem.confirmPassword,
        };
        this.$axios
          .post("Account/ChangeUserPasswordByAdmin", changePasswordModel)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.closeChangePassword();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }

            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.refreshTable();

            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
